@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap");
@import "./themes/variables";

* {
  margin: 0;
  padding: 0;
}

html,
body,
* {
  font-family: $font-family;
}

html,
body {
  background: #f6f6f6;
  background-color: #f6f6f6 !important;
  padding-right: 0px !important;
}

body {
  overflow-x: hidden;
}

// Not found page styles

@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:wght@200;500&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --primary-color: #fc7912;
  --eye-pupil-color: #050505;
  --bg-color: #fff;
  --text-color: #000;
  --fs-heading: 36px;
  --fs-text: 26px;
  --fs-button: 18px;
  --fs-icon: 30px;
  --pupil-size: 30px;
  --eye-size: 80px;
  --button-padding: 15px 30px;

  @media only screen and (max-width: 567px) {
    --fs-heading: 30px;
    --fs-text: 22px;
    --fs-button: 16px;
    --fs-icon: 24px;
    --button-padding: 12px 24px;
  }
}

.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 30px;
  text-align: center;
  padding-top: 30vh;
}

.error-page {
  margin: auto;

  &__heading {
    &-title {
      text-transform: capitalize;
      font-size: var(--fs-heading);
      font-weight: 500;
      color: var(--primary-color);
    }

    &-desciption {
      margin-top: 10px;
      font-size: var(--fs-text);
      font-weight: 200;
    }
  }

  &__button {
    color: inherit;
    text-decoration: none;
    border: 1px solid var(--primary-color);
    font-size: var(--fs-button);
    font-weight: 200;
    padding: var(--button-padding);
    border-radius: 15px;
    box-shadow: 0px 7px 0px -2px var(--primary-color);
    transition: all 0.3s ease-in-out;
    text-transform: capitalize;

    &:hover {
      box-shadow: none;
      background-color: var(--primary-color);
      color: #fff;
    }
  }
}

.eyes {
  display: flex;
  justify-content: center;
  gap: 2px;
}

.eye {
  width: var(--eye-size);
  height: var(--eye-size);
  background-color: var(--primary-color);
  border-radius: 50%;
  display: grid;
  place-items: center;

  &__pupil {
    width: var(--pupil-size);
    height: var(--pupil-size);
    background-color: var(--eye-pupil-color);
    border-radius: 50%;
    animation: movePupil 2s infinite ease-in-out;
    transform-origin: center center;

    /*
    To reverse the animation of the right eye, uncomment this.
    */
    // &--right {
    // 	animation-direction: reverse;
    // }
  }

  @keyframes movePupil {
    0%,
    100% {
      transform: translate(0, 0);
    }
    25% {
      transform: translate(-10px, -10px);
    }
    50% {
      transform: translate(10px, 10px);
    }
    75% {
      transform: translate(-10px, 10px);
    }
  }
}

.color-switcher {
  position: fixed;
  top: 40px;
  right: 40px;
  background-color: transparent;
  font-size: var(--fs-icon);
  cursor: pointer;
  color: var(--primary-color);
  border: 0;
}

// Not found page styles

//Spinner styles

.loading-state {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 18vw;

  .loading {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 5px solid #ddd;
    border-top-color: #fc7912;
    animation: loading 1s linear infinite;
  }
}

.loading-state-changed {
  width: 100%;
  height: 100%;
  min-height: 60vh;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 8vw;

  .loading {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 5px solid #ddd;
    border-top-color: #fc7912;
    animation: loading 1s linear infinite;
  }
}

@keyframes loading {
  to {
    transform: rotate(360deg);
  }
}

// Spinner styles

.loaderdiv {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 111;
  background: $white-color;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*Login Css Start*/
.loginbody {
  background: url("components/assets/img/bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  position: relative;
  width: 100%;
  z-index: 1;
  background-attachment: fixed;
  &:after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: $black-color;
    opacity: 0.7;
  }

  a {
    position: relative;
    z-index: 1;

    img {
      height: 100px;
    }
  }
}

.loginform {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  z-index: 1;

  .loginmain_div {
    background: $white-color;
    border-radius: 20px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.102);
    padding: 30px;
    width: 100%;

    .headlogin_div {
      text-align: center;
      h2 {
        font-size: 32px;
        color: $dark-color;
        font-weight: 700;
      }
      .loginlogo_img {
        margin-bottom: 10px;
        max-width: 180px;
      }
      p {
        font-size: 14px;
        color: $font-color;
      }
    }
  }
}

.maxwidth500 {
  max-width: 500px;
}

.btn-primary {
  background: $primary-color !important;
  border-color: $primary-color !important;
  height: 54px;
  font-size: 16px !important;
  font-weight: 700 !important;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  border-radius: 10px !important;
  &:hover {
    opacity: 0.75;
  }
}

.btn-primary-outline {
  background: transparent !important;
  border-color: $primary-color !important;
  color: $primary-color !important;
  height: 54px;
  font-size: 16px !important;
  font-weight: 700 !important;

  &:hover {
    opacity: 0.75;
  }
}
.btn-primary-light {
  background: rgba($primary-color, 0.1) !important;
  border-color: rgba($primary-color, 0.1) !important;
  color: $primary-color !important;
  height: 54px;
  font-size: 16px !important;
  font-weight: 700 !important;
  &:hover {
    opacity: 0.75;
  }
}

Form {
  .formfooter {
    font-size: 16px;
    color: $dark-color;
    margin-bottom: 0px;
    a {
      color: $primary-color;
      text-decoration: underline;
      font-weight: 600;
    }
  }

  .forgotpassword {
    a {
      font-size: 16px;
      text-decoration: underline;
      color: $dark-color;
      font-weight: 600;
    }
  }

  .form-group {
    .uploadphoto {
      border: 1px dashed $border-color;
      border-radius: 8px;
      padding: 30px 10px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      cursor: pointer;

      svg {
        font-size: 30px;
        color: $primary-color;
        margin-bottom: 5px;
      }

      p {
        color: $font-color;
        margin-bottom: 0px;
      }
    }

    label {
      font-size: 16px;
      color: $dark-color;
      font-weight: 600;
      margin-bottom: 3px;
    }

    textarea.form-control {
      height: auto;
      padding-top: 15px;
      padding-bottom: 15px;
    }

    .form-control {
      border: 1px solid $border-color;
      height: 52px;
      border-radius: 8px;
      font-size: 14px;
      padding: 0px 16px;
      box-shadow: none;

      &::placeholder {
        color: $font-color;
      }
    }
    .form-select {
      border: 1px solid $border-color;
      height: 52px;
      border-radius: 8px;
      font-size: 14px;
      padding: 0px 16px;
      box-shadow: none;

      &::placeholder {
        color: $font-color;
      }
      &.select_measurement {
        width: 110px;
      }
    }
  }
  .fileupload {
    width: 100%;
    border: 2px dashed #ddd;
    border-radius: 4px;
    padding: 30px;
    text-align: center;
    margin-bottom: 18px;
    cursor: pointer;
    .uplaodicon {
      color: #070d29;
      font-size: 40px;
    }
    h3 {
      font-size: 16px;
      color: #000;
      margin-top: 8px;
      margin-bottom: 0px;
    }
  }
  .displaynone {
    display: none;
  }
  .formimg {
    display: inline-block;
    position: relative;
    margin-top: 10px;
    margin-right: 10px;
    .closebtn {
      width: 20px;
      cursor: pointer;
      height: 20px;
      background: #e60d0d;
      text-align: center;
      line-height: 20px;
      color: #fff;
      border-radius: 50%;
      position: absolute;
      right: -5px;
      top: -5px;
      padding: 3px;
    }
    .videoplay_icon {
      text-align: center;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 26px;
      color: $primary-color;
      transform: translate(-50%, -50%);
    }
    img {
      width: 80px;
      height: 80px;
      object-fit: cover;
      border-radius: 10px;
      border: 1px solid #e9e9e9;
    }
  }
  .timesetting_div {
    .form-check {
      display: flex;
      align-items: center;
      .form-label {
        display: flex;
        align-items: center;
        gap: 15px;
        flex-grow: 1;
        .day_badge {
          background: $primary-color;
          padding: 10px 15px;
          border-radius: 10px;
          text-align: center;
          display: inline-block;
          color: #fff;
          font-size: 16px;
          font-weight: 600;
          min-width: 70px;
        }
      }
    }
  }
  .react-datepicker-wrapper {
    display: block;
  }
  .addfilter_relative {
    position: relative;
    display: flex;
    gap: 15px;
    .form-control {
      flex-grow: 1;
    }
    .clickadd_btn {
      flex-shrink: 0;
      height: 52px;
    }
  }
  .filteraddlist_box {
    padding-top: 10px;
    .filteradd_list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px 15px;
      background: #f5f5f5;
      margin: 6px 0px;
      border-radius: 8px;
      p {
        font-size: 16px;
        font-weight: 400;
        color: $black-color;
        margin-bottom: 0px;
      }
      .addfilterremove_btn {
        text-decoration: none;
        font-size: 20px;
        display: inline-flex;
        color: #b90000;
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
}

/*Login Css Ends*/
/*Membership Packages Css Start*/
.pricingpackage {
  border-radius: 16px;
  padding: 20px;
  background: $white-color;
  box-shadow: 0px 0px 10px rgba($black-color, 0.1);
  overflow: hidden;

  .pricinghead {
    text-align: center;
    position: relative;

    .setdefault {
      background: rgba($primary-color, 0.1);
      color: $primary-color;
      position: absolute;
      right: -20px;
      padding: 5px 10px 4px;
      font-size: 10px;
      top: -21px;
      font-weight: 500;
    }

    h5 {
      font-size: 16px;
      color: $dark-color;
      font-weight: 600;
    }

    h2 {
      font-size: 30px;
      font-weight: 700;
      color: $primary-color;
    }

    h6 {
      font-size: 14px;
      color: $dark-color;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      span {
        display: block;
        margin-top: 4px;
        font-size: 10px;
        font-style: italic;
        font-weight: 600;
      }
    }
  }

  ul {
    list-style: none;
    margin: 20px 0px 0px 0px;
    padding: 20px 0px 20px 0px;
    border-top: 1px solid rgba($black-color, 0.1);

    li {
      font-size: 13px;
      color: $dark-color;
      display: flex;
      margin-bottom: 10px;
      justify-content: space-between;
      margin-bottom: 10px;

      svg {
        color: $primary-color;
        font-size: 18px;
        margin-left: 5px;
      }
    }
  }

  &.activepackage {
    background: $primary-color;

    .pricinghead {
      text-align: center;

      h5 {
        color: $white-color;
      }

      h2 {
        color: $white-color;
      }

      h6 {
        color: $white-color;
      }
    }

    ul {
      border-top: 1px solid rgba($white-color, 0.5);

      li {
        color: $white-color;
      }
    }

    .btn-primary {
      background: $white-color !important;
      color: $dark-color !important;
    }
  }
}

.minheight33 {
  min-height: 33px;
}

.headinner {
  h4 {
    font-size: 20px;
    color: $dark-color;
  }

  p {
    color: $font-color;
    font-size: 14px;
  }
}

.h300 {
  height: 300px !important;
}

.paymentdone {
  h2 {
    font-size: 28px;
    margin-top: 25px;
  }
}

.modalhead {
  font-size: 20px;
  margin: 0px;
  font-weight: 700;
}

.modalsubhead {
  font-size: 14px;
  margin: 0px;
}

.btn-secondary {
  border: 1px solid rgba($black-color, 0.1) !important;
  background: transparent !important;
  color: rgba($black-color, 0.5) !important;
}

/*Membership Packages Css Ends*/
@media only screen and (max-width: 767.98px) {
  .header,
  .maincontent_div {
    margin-left: 0 !important;
  }

  button.btnclose,
  button.btnopen {
    border: 0 !important;
    display: block !important;
    outline: 0 !important;
    z-index: 101;
  }

  button.btnopen {
    background: transparent !important;
    box-shadow: none !important;
    left: 15px;
    position: absolute;
    top: 30px;

    svg {
      font-size: 34px;
    }
  }
}

.sidebar {
  background: $gradient-color;
  height: 100%;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 20px 10px 20px 20px;
  position: fixed;
  scrollbar-color: rgba($white-color, 0.5) transparent;
  scrollbar-width: thin;
  box-shadow: 0px 0px 10px rgba($black-color, 0.07);
  top: 0;
  width: 270px;
  z-index: 1031;

  .sidebarlogo_div {
    text-align: center;
    border-bottom: 1px solid rgba($white-color, 0.4);
    padding-bottom: 20px;
    margin-bottom: 20px;

    img {
      height: 100px;
    }
  }

  @media only screen and (max-width: 767.98px) {
    &.sidebar-open {
      left: 0;
      transition: all 1s ease-in-out;
    }

    &.sidebar-closed {
      left: -320px;
      transition: all 1s ease-in-out;
    }
  }
}

@media only screen and (max-width: 767.98px) {
  button.btnclose {
    background: rgba($black-color, 0.5);
    bottom: 0;
    left: 0;
    padding: 0;
    position: fixed;
    top: 0;
    transition: all 1s ease-in-out;

    &.sidebarbg-open {
      transition: all 1s ease-in-out;
      width: 100%;
    }
  }
}

.header {
  margin-left: 270px;
  position: relative;
  z-index: 1;
  padding: 10px 15px;

  .headerright {
    background: $white-color;
    padding: 4px;
    border-radius: 50px;

    .linksheader {
      svg {
        font-size: 16px;
        color: $dark-color;
      }
    }

    .dropdown {
      .dropdown-toggle {
        background: transparent !important;
        border: none !important;

        &::after {
          display: none;
        }
      }
    }

    a {
      display: inline-flex;

      &:hover {
        opacity: 0.75;
        cursor: pointer;
      }
    }
  }

  .headerright {
    img {
      width: 45px;
      height: 45px;
      border-radius: 50%;
    }
  }
}

.maincontent_div {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  margin-left: 270px;
  padding: 15px;
  position: relative;
  transition: all 0.3s ease;
}

.form-check {
  .form-check-input:checked {
    background-color: $primary-color;
    border-color: $primary-color;
  }

  .form-check-label {
    font-size: 14px;
  }
}

.mainbreadcrumb {
  h1 {
    font-size: 28px;
    color: $black-color;
    font-weight: 700;
    margin-bottom: 0px;
  }

  nav {
    ol {
      margin-bottom: 5px;

      li {
        a {
          color: $black-color;
          font-size: 14px;
          font-weight: 500;
          text-decoration: none;
        }
      }
    }
  }
}

.sidebarouter {
  ul {
    list-style: none;
  }

  .submenu {
    padding-left: 20px;
    margin-bottom: 10px;
    .submenu-item {
      display: block;
      text-decoration: none;
      font-size: 14px;
      color: rgba($white-color, 0.7);
      padding: 3px 0px;
      &.active {
        color: $black-color;
      }
    }
  }
  .navitem {
    padding: 12px 20px;
    position: relative;
    color: $white-color;
    font-size: 14px;
    text-decoration: none;
    margin-bottom: 10px;
    display: flex;
    font-weight: 500;

    svg {
      font-size: 20px;
      margin-right: 10px;
      min-width: 20px;
    }

    &:before {
      content: "";
      position: absolute;
      top: 0px;
      left: -20px;
      height: 100%;
      background: $black-color;
      width: 0;
      border-radius: 0px 14px 14px 0px;
    }

    &.active,
    &:hover {
      &:before {
        width: 4px;
      }

      color: $white-color;
      background: $black-color;
      display: flex;
      border-radius: 6px;
    }
  }
}
.stats {
  background: $white-color;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba($black-color, 0.07);
  display: flex;
  align-items: center;

  .statsicon {
    width: 70px;
    height: 70px;
    background: rgba($primary-color, 0.07);
    border-radius: 50%;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &.statsblue {
      background: rgba(176, 159, 245, 0.12);

      svg {
        color: #b09ff5;
      }
    }

    &.statsgreen {
      background: rgba(40, 199, 111, 0.12);

      svg {
        color: #28c76f;
      }
    }

    svg {
      font-size: 24px;
      color: $primary-color;
    }
  }

  .stats-cont {
    width: calc(100% - 50px);
    padding-left: 15px;

    h4 {
      font-size: 16px;
      color: $black-color;
      font-weight: 600;
    }

    p {
      margin-bottom: 0px;
      font-size: 14px;
    }
  }
}

.maxwidth120 {
  max-width: 120px;
}

.lh-40 {
  line-height: 40px !important;
}

.card {
  border: none !important;
  --bs-border-radius: 12px !important;
  box-shadow: 0px 0px 10px rgba($black-color, 0.07);

  .card-header {
    background: $white-color;
    padding-top: 12px;
    padding-bottom: 12px;

    .form-select {
      font-size: 14px;
      box-shadow: none;
      outline: 0px;
      border: 1px solid #dee2e6;
    }

    h5 {
      font-size: 18px;
      margin-bottom: 0;
      font-weight: 600;
    }
  }
  .card-body {
    h4.formmain_head {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 10px;
      color: $black-color;
    }
  }
}

.searchfield {
  border: 1px solid #dadada;
  border-radius: 8px;
  box-shadow: none;
  font-size: 14px;
  padding: 8px 16px;
  outline: 0px;
}

.rdt_Pagination {
  margin-top: 5px;
}

.post-image {
  width: 60px;
  min-width: 60px;
  height: 60px;
  object-fit: cover;
  border: 1px solid #e0e0e0;
  padding: 4px;
  border-radius: 10px;
}

.video-icon {
  position: absolute;
  font-size: 30px;
  color: $white-color;
  left: 32px;
  background: rgba($black-color, 0.35);
  border-radius: 50%;
}
.custom-table {
  scrollbar-width: thin;
  scrollbar-color: #ccc transparent;

  .rdt_TableHead {
    .rdt_TableHeadRow {
      background: rgba($primary-color, 0.07);
      border-radius: 10px;
      margin: 5px 0;
      padding-top: 15px;
      padding-bottom: 15px;

      .rdt_TableCol {
        color: $dark-color;
        font-weight: 600;
        font-size: 14px;
      }
    }
  }

  .table_img {
    width: 60px;
    min-width: 60px;
    height: 60px;
    object-fit: cover;
  }
  .rdt_TableBody {
    .rdt_TableRow {
      .rdt_TableCell {
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 14px;
      }

      border-radius: 10px;
      background: #fff;
      border: 1px solid #e0e0e0;
      margin: 5px 0;

      .tag {
        color: $primary-color;
        font-weight: 500;
        margin-left: 4px;
      }
    }
  }
}

.tabledropdown {
  border: 1px solid #dadada !important;
  border-radius: 6px !important;
  box-shadow: none !important;
  font-size: 13px !important;
  max-width: 150px;
}

@mixin btnaction {
  width: 34px;
  min-width: 34px;
  height: 34px;
  font-size: 18px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0px 3px;
  color: $white-color;
}

.btnview {
  background: $primary-color;
  @include btnaction;
}

.btnedit {
  @include btnaction;
  background: #28c76f;
}

.btndelete {
  @include btnaction;
  background: #d32424;
}

.postimgvideo {
  label {
    width: 45px;
    min-width: 45px;
    height: 45px;
    background: rgba($primary-color, 0.1);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;

    svg {
      font-size: 24px;
      color: $primary-color;
    }
  }
}

.modal {
  .modal-body {
    .postlist {
      align-items: center;
      border: 1px solid $border-color;
      border-radius: 15px;
      display: flex;
      margin-bottom: 12px;
      padding: 15px;

      img {
        height: 40px;
        object-fit: cover;
        width: 40px;
      }

      h2 {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 0;
        margin-left: 13px;
      }
    }

    .btn-primary {
      height: 48px;
      font-size: 14px !important;
    }
  }
}

.menugrid {
  position: relative;

  img {
    height: 140px;
    width: 100%;
    box-shadow: 0px 0px 10px rgba($black-color, 0.1);
    object-fit: cover;
  }

  .deletemenu {
    width: 22px;
    min-width: 22px;
    height: 22px;
    background: $primary-color;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    color: $white-color;
    position: absolute;
    top: 5px;
    right: 5px;
  }
}

.uploadmenu {
  width: 100%;
  border: 1px dashed $border-color;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  svg {
    font-size: 32px;
    color: $primary-color;
  }
}

.addicon {
  width: 45px;
  min-width: 45px;
  height: 45px;
  background: rgba($primary-color, 0.1) !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: none;
  color: $primary-color;
  font-size: 20px !important;
}

.modal {
  &.itemmodal {
    .modal-content {
      .modal-body {
        Form {
          .form-group {
            .form-control {
              height: 45px;
            }

            textarea.form-control {
              height: auto;
            }
          }
        }
      }
    }
  }
}

.bookingstab {
  .customtabs {
    float: left;
  }
}

.customtabs {
  &.nav-tabs {
    border: none;

    li {
      &.nav-item {
        button {
          &.nav-link {
            border: 1px solid rgba($primary-color, 0.1);
            border-radius: 40px;
            color: $primary-color;
            font-weight: 500;
            padding: 10px 20px;
            font-size: 15px;
            margin: 0px 5px 10px 0px;
            background: rgba($primary-color, 0.1);

            &.active,
            &:hover {
              background: $primary-color;
              color: $white-color;
            }
          }
        }
      }
    }
  }
}
.mt_30 {
  margin-top: 30px;
}
.view_profile {
  .user_info {
    border: 1px solid #dee2e6;
    border-radius: 12px;
    padding: 30px 20px;
    position: relative;
    overflow: hidden;

    .profilepic {
      width: 140px;
      height: 140px;
      object-fit: cover;
    }

    h4 {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 5px;
      margin-top: 15px;
    }

    p {
      font-size: 14px;
      margin-bottom: 0px;
    }
  }

  .form-group {
    border-bottom: 1px dashed #dee2e6;
    padding-bottom: 13px;

    span {
      font-size: 14px;
    }

    .form-label {
      font-weight: 600;
      font-size: 14px;
    }
  }
}

.font-size-14 {
  font-size: 14px;
}

.postsdetail {
  .replyBox {
    display: flex;

    img {
      width: 40px;
      min-width: 40px;
      height: 40px;
      border: 1px solid $primary-color;
      padding: 2px;
      object-fit: cover;
    }

    .commentarea {
      width: calc(100% - 50px);

      .form-control {
        font-size: 14px;
        height: 40px;
        margin-left: 10px;
        padding-right: 45px;
      }

      button {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        padding: 0;
        position: absolute;
        top: 5px;
        right: 0px;
      }
    }
  }

  .aftContent {
    width: 100%;

    img {
      width: 40px;
      min-width: 40px;
      height: 40px;
      border: 1px solid $primary-color;
      padding: 2px;
      object-fit: cover;
    }

    .comBox {
      width: calc(100% - 40px);

      // margin-left: 10px;
      .cardCount {
        .cardcountlinks {
          font-size: 11px;

          svg {
            font-size: 15px;
          }
        }
      }

      .repCommnet {
        background-color: #edf2f6;
        border-radius: 10px;
        max-width: 100% !important;
        min-width: 140px;
        padding: 14px 10px 14px 14px;

        .dropdown {
          button {
            &.dropdown-toggle {
              &.btn-primary {
                width: 16px;
                height: 16px;
                display: inline-flex;
                transform: translateY(-4px);
              }
            }
          }
        }

        h3 {
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 7px;
        }

        p {
          font-size: 12px;
          margin-bottom: 0;
        }
      }
    }
  }

  .cardCount {
    margin-top: 10px;

    .cardcountlinks {
      text-decoration: none;
      display: inline-flex;
      align-items: center;
      color: rgba($black-color, 0.5);
      font-size: 13px;
      font-weight: 500;

      svg {
        font-size: 18px;
        margin-right: 3px;
      }
    }
  }

  .post-description {
    font-size: 14px;

    .tag {
      color: $primary-color;
      font-weight: 500;
    }
  }

  .dropdown {
    button {
      &.dropdown-toggle {
        &.btn-primary {
          background: transparent !important;
          border: none !important;
          color: $black-color !important;
          padding: 0px;
          width: 20px;
          height: 20px;
          line-height: normal;

          svg {
            font-size: 20px;
          }
        }

        &:after {
          display: none;
        }
      }
    }

    .dropdown-menu {
      min-width: 100px;
      border-radius: 15px 0px 15px 15px;
      box-shadow: 0px 0px 10px rgba($black-color, 0.07);

      .dropdown-item {
        font-size: 14px;
        color: $dark-color;
        font-weight: 500;

        &:active,
        &:hover {
          background: rgba($primary-color, 0.1);
          color: $primary-color;
        }
      }
    }
  }

  .mediaUser {
    align-items: center;
    display: flex;

    img {
      border: 1px solid $primary-color;
      height: 60px;
      object-fit: cover;
      width: 60px;
      min-width: 60px;
      padding: 3px;
    }

    .mediaContent {
      margin-left: 1em;

      h3 {
        font-size: 15px;
        color: $dark-color;
        font-weight: 600;
      }

      h4 {
        font-size: 13px;
        margin-bottom: 0px;
        opacity: 0.7;
      }
    }
  }

  .mediaimg {
    img {
      height: 450px;
      object-fit: cover;
      border-radius: 12px;
    }
  }
}

.bookingtable {
  .table-responsive {
    scrollbar-color: #e3e3e3 transparent;
    scrollbar-width: thin;
  }
  > .table-responsive {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07);
    border-radius: 10px;

    table {
      &.table {
        &.tableinner {
          border-collapse: separate;
          border-spacing: 0px 10px;

          thead {
            tr {
              th {
                background: rgba($primary-color, 0.07);
                padding: 15px;
                &:first-child {
                  border-radius: 10px 0px 0px 10px;
                }
                &:last-child {
                  border-radius: 0px 10px 10px 0px;
                }
              }
            }
          }
          tbody {
            tr {
              td {
                padding: 15px;
                border-top: 1px solid #dee2e6;
                border-bottom: 1px solid #dee2e6;
                vertical-align: middle;
                &:first-child {
                  border-radius: 10px 0px 0px 10px;
                  border-left: 1px solid #dee2e6;
                }
                &:last-child {
                  border-radius: 0px 10px 10px 0px;
                  border-right: 1px solid #dee2e6;
                }
              }
            }
          }
        }

        tr {
          td {
            font-size: 14px;
            padding: 15px;
            b {
              font-weight: 600;
            }
            .dtlimg {
              width: 80px;
              height: 80px;
              object-fit: cover;
            }
          }
        }
      }
    }
  }
  .dtlmain_head {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 10px;
    color: $black-color;
    border-left: 6px solid $primary-color;
    padding-left: 10px;
  }
  .dateinvoice_box {
    h3 {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 10px;
      color: $black-color;
    }
    .dateinvoice_name {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 10px;
      color: $black-color;
    }
    .dateinvoice_email {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 0px;
      color: $black-color;
    }
    .dateinvoice_dtl {
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 0px;
      color: rgba($black-color, 0.7);
    }
  }
}
.notificationlist {
  box-shadow: 0px 0px 10px rgba($black-color, 0.07);
  padding: 25px;
  border-radius: 12px;
  border-left: 3px solid $primary-color;
  background: $white-color;
  h2 {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 0px;
  }
  p {
    margin-bottom: 0;
    font-size: 14px;
  }
  .notifytime {
    font-size: 13px;
    display: inline-flex;
    align-items: center;
    svg {
      margin-right: 4px;
    }
  }
}
.vieworderdetail {
  p {
    display: flex;
    justify-content: space-between;
  }
}
.Active.badge,
.Completed.badge,
.badge.badge_completed {
  background-color: rgba(40, 199, 111, 0.12);
  color: #28c76f;
  display: inline-block;
  font-size: 13px;
  line-height: normal;
}
.badge.adventure_badge {
  font-size: 12px;
  line-height: normal;
}
.Inactive.badge,
.Pending.badge {
  background-color: rgba(146, 0, 0, 0.12);
  color: #920000;
  display: inline-block;
  font-size: 13px;
  line-height: normal;
}
.min_width140 {
  min-width: 140px;
}
.min_width110 {
  min-width: 110px;
}
.ck-editor__editable {
  min-height: 200px;
}
table.openinghourtable.table {
  tbody {
    tr {
      &:last-child {
        td {
          border: 0px;
        }
      }
    }
  }
}
.modal_Delete.modal {
  .modal-content {
    border: 0px;
    border-radius: 15px;
    .modal-body {
      text-align: center;
      padding: 25px;
      .modaldelete_div {
        margin-bottom: 25px;
        .delete_icon {
          font-size: 60px;
          color: #fd5353;
          margin-bottom: 10px;
        }
        h3 {
          color: $black-color;
          font-size: 24px;
          font-weight: 700;
          margin-bottom: 10px;
          line-height: normal;
        }
        p {
          color: #8b8b8b;
          font-size: 16px;
          font-weight: 400;
          margin-bottom: 0px;
        }
      }
    }
  }
}
.bookingtable {
  .tab-content {
    .tabdtl_table {
      .table-responsive {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07);
        border-radius: 10px;
        scrollbar-color: #e3e3e3 transparent;
        scrollbar-width: thin;

        table {
          &.table {
            tr {
              td {
                font-size: 14px;
                padding: 15px;
                b {
                  font-weight: 600;
                }
                .dtlimg {
                  width: 80px;
                  height: 80px;
                  object-fit: cover;
                }
              }
            }
          }
        }
      }
    }
  }
}
.w_90 {
  width: 90px;
}
.viewtable_box {
  table {
    border-collapse: separate;
    border-spacing: 0px 10px;

    thead {
      tr {
        th {
          background: $black-color;
          padding: 20px 15px;
          color: $white-color;
          font-size: 14px;
          border: 0px;

          &:first-child {
            border-radius: 10px 0px 0px 10px;
          }

          &:last-child {
            border-radius: 0px 10px 10px 0px;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          padding: 15px;
          border-top: 1px solid #dee2e6;
          border-bottom: 1px solid #dee2e6;
          vertical-align: middle;
          font-size: 14px;

          &:first-child {
            border-radius: 10px 0px 0px 10px;
            border-left: 1px solid #dee2e6;
          }

          &:last-child {
            border-radius: 0px 10px 10px 0px;
            border-right: 1px solid #dee2e6;
          }
        }
      }
    }
  }
}
.datedtl_box {
  background: rgba($primary-color, 0.07);
  padding: 20px;
  border-radius: 15px;
  height: 100%;
  h4 {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 10px;
    color: $black-color;
  }
  .date_text {
    font-size: 14px;
    margin-bottom: 6px;
    line-height: normal;
    color: rgba($black-color, 0.8);
  }
  .price_text {
    font-size: 14px;
    margin-bottom: 6px;
    line-height: normal;
    color: rgba($black-color, 0.8);
  }
  .date_desc {
    font-size: 14px;
    margin-bottom: 0px;
    line-height: normal;
    color: rgba($black-color, 0.8);
  }
}

//New styles added
.cate_image_change {
  background-color: #ccc !important;
  width: 20px !important;
  padding: 10px !important;
  object-fit: contain !important;
}
.cate-icon {
  background-color: #ccc !important;
}
.spinner-view {
  background-color: #ccc;
  border: 1px solid #e9e9e9;
  border-radius: 10px;
  height: 80px;
  display: flex;
  object-fit: cover;
  width: 80px;
  justify-content: center;
  align-items: center;
}
.category-status {
  padding: 10px;
  background-color: $primary-color;
  text-decoration: none;
  border-radius: 10px;
  margin-left: 10px;
  p {
    font-size: 12px;
    margin: 0;
    color: #fff;
  }
}
.profilePicLoadingView {
  width: 140px;
  height: 140px;
  object-fit: cover;
  background-color: #ccc;
  border-radius: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pac-container.pac-logo {
  z-index: 9999999999999;
}

input.pac-target-input {
  display: block;
  border: 1px solid #dadada;
  height: 52px;
  border-radius: 8px;
  font-size: 14px;
  padding: 0px 16px;
  box-shadow: none;
  width: 100%;
}
.image-thumbnail-changed {
  height: 410px;
  width: 50%;
  object-fit: cover;
  object-position: top;
}

.accordion {
  .customButton {
    border: 1px solid #fc7912;
    margin: 5px;
    border-radius: 30px;
    padding: 5px;
    font-size: 12px;
    padding-left: 10px;
    padding-right: 10px;
    background: #fc7912;
    color: #fff;
  }
}

.accordion-button:not(.collapsed) {
  background-color: transparent !important;
  box-shadow: none !important;
}
div#accordionExample {
  margin-bottom: 10px;
}
.noResultFound {
  width: 100%;
  height: 450px;
  object-fit: contain;
  padding-top: 50px;
}
.noResultFoundText {
  text-align: center;
    margin-bottom: 50px;
}
